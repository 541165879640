.submenu {
  background-color: #fff;
  min-width: 180px;
  border-radius: 4px;
  box-shadow: 0px 12px 32px rgba(26, 26, 26, 0.2);
  z-index: 1100;
  position: absolute;
  display: none;
  top: -5px;
  right: 98%;
}

.submenu > ul {
  list-style: none;
  padding-left: 0;
  margin-top: 12px;
  margin-bottom: 12px;
}

.submenuRight {
  left: 98%;
}